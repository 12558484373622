import React, {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {BackButton} from "../../ui/buttons/BackButton";
import {
    ProductionOrder,
    ProductionOrderStatus,
    UpdateOrCreateProductionOrderRequest
} from "../../../model/production-order/ProductionOrder";
import {useNavigate} from "react-router-dom";
import {ControlledPaginateAsyncSelect} from "../../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {OptionType} from "../../../model/option-type/OptionType";
import {
    useCreateProductionOrderMutation,
    useDeleteProductionOrderMutation,
    useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery,
    useUpdateProductionOrderMutation,
    useUpdateProductionOrderStatusMutation
} from "../../../store/backend-api/backend.api";
import {TablePage} from "../../../model/utils/TablePage";
import {
    AdministrativeAndFunctionalZonePurpose
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {
    AdministrativeAndFunctionalZoneGuideRow
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideRow";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {TableForMainProduct} from "./TableForMainProduct";
import {ProductionOrderExecutionTable} from "./ProductionOrderExecutionTable";
import {ExecutionByProductionStagesTable} from "./ExecutionByProductionStagesTable";
import {SeparatingLine} from "../../ui/common/SeparatingLine";
import {ControlledInputField} from "../../ui/form-components/controlled/ControlledInputField";
import {ControlledDatePicker} from "../../ui/form-components/controlled/ControlledDatePicker";
import {BlockForDisplayingText} from "./BlockForDisplayingText";
import {Button} from "../../ui/buttons/Button";
import {TableForAdditionalEquipments} from "./TableForAdditionalEquipments";
import {formButtonGroupStyle, formColumnsStyle, formHeaderStyle, formStyle} from "../../../utils/TailwindStyles";

interface FormProps {
    data?: ProductionOrder,
    readonly?: boolean
}

export interface ProductionOrderFormFields {
    documentNumber: number | null,
    technologicalProcessChart: OptionType<number> | null,
    productionLine: OptionType<number> | null,
    nomenclature: OptionType<number> | null,
    quantity: number,
    workOrderNumber: string,
    priority: string,
    planningCompletionDate: Date | null,
    productionComment: string,
    additionalEquipments: {
        number: string,
        nomenclature: OptionType<number> | null,
        quantity: number,
        technologicalProcessChart: OptionType<number> | null
    }[]
}

export function ProductionOrderFormTemplate(props: FormProps) {
    const [doSearchAdministrativeAndFunctionalZoneGuidePage] = useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery()
    const [doCreateProductionOrder, {
        data: createdProductionOrderNumber,
        isSuccess: isSuccessCreate
    }] = useCreateProductionOrderMutation()
    const [doUpdateProductionOrder, {isSuccess: isSuccessUpdate}] = useUpdateProductionOrderMutation()
    const [doUpdateProductionOrderStatus, {isSuccess: isSuccessUpdateStatus}] = useUpdateProductionOrderStatusMutation()
    const [doDeleteProductionOrder, {isSuccess: isSuccessDelete}] = useDeleteProductionOrderMutation()
    const defaultFormValues: ProductionOrderFormFields = props.data ? {
        documentNumber: props.data.documentNumber,
        technologicalProcessChart: props.data.mainProduct?.technologicalProcessChart ? {
            label: `${props.data.mainProduct.technologicalProcessChart.nomenclature.name}, ${props.data.mainProduct.technologicalProcessChart.nomenclature.mainSKU || "–"}`,
            value: props.data.mainProduct.technologicalProcessChart.id
        } : null,
        nomenclature: props.data.mainProduct ? {
            label: `${props.data.mainProduct.nomenclature.name}, ${props.data.mainProduct.nomenclature.mainSKU || "–"}`,
            value: props.data.mainProduct.nomenclature.id
        } : null,
        quantity: props.data.mainProduct?.quantity ? +props.data.mainProduct?.quantity : 0,
        productionLine: props.data.productionLine ? {label: `${props.data.productionLine.name}`, value: props.data.productionLine.id} : null,
        workOrderNumber: `${props.data.workOrderInfo.number}`,
        priority: props.data && props.data.priority ? `${props.data.priority}` : "",
        planningCompletionDate: new Date(props.data.planningCompletionDate),
        productionComment: props.data.productionComment,
        additionalEquipments: [...props.data.additionalEquipment].sort((equipment1, equipment2) => equipment1.number - equipment2.number).map(equipment => ({
            number: equipment.number.toString(),
            nomenclature: equipment.nomenclature ? {
                label: `${equipment.nomenclature.name}, ${equipment.nomenclature.mainSKU || "–"}`,
                value: equipment.nomenclature.id
            } : null,
            quantity: +equipment.quantity,
            technologicalProcessChart: equipment.technologicalProcessChart ? {
                label: `${equipment.technologicalProcessChart.nomenclature.name}, ${equipment.technologicalProcessChart.nomenclature.mainSKU || "–"}`,
                value: equipment.technologicalProcessChart.id
            } : null
        }))
    } : {
        documentNumber: null,
        technologicalProcessChart: null,
        nomenclature: null,
        quantity: 0,
        productionLine: null,
        workOrderNumber: "",
        priority: "",
        planningCompletionDate: null,
        productionComment: "",
        additionalEquipments: []
    }
    const navigate = useNavigate()
    const {control, handleSubmit, formState: {isDirty}} = useForm({mode: "onChange", defaultValues: defaultFormValues})
    const onClickSaveButton: SubmitHandler<ProductionOrderFormFields> = data => {
        const requestData: UpdateOrCreateProductionOrderRequest = {
            priority: data.priority ? +data.priority : null,
            productionLineId: data.productionLine!.value,
            numberFromWork: +data.workOrderNumber,
            targetItem: {
                nomenclatureId: data.nomenclature!.value,
                quantity: data.quantity.toString(),
                technologicalProcessChartId: data.technologicalProcessChart?.value ?? null
            },
            additionalItems: data.additionalEquipments.map(equipment => ({
                nomenclatureId: equipment.nomenclature!.value,
                quantity: equipment.quantity.toString(),
                technologicalProcessChartId: equipment.technologicalProcessChart?.value ?? null
            })),
            productionComment: data.productionComment,
            planningCompletionDate: data.planningCompletionDate!
        }
        if (props.data && isDirty) {
            doUpdateProductionOrder({number: props.data.documentNumber, body: requestData})
        } else if (!props.data) {
            doCreateProductionOrder(requestData)
        }
    }

    useEffect(() => {
        if (isSuccessCreate) {
            navigate(`/production-order?number=${createdProductionOrderNumber}`)
        }
        if (isSuccessUpdate || isSuccessUpdateStatus) {
            navigate(`/production-order?number=${props.data!.documentNumber}`)
        }
        if (isSuccessDelete) {
            navigate('/production-order/document-journal')
        }
    }, [isSuccessCreate, isSuccessUpdate, isSuccessUpdateStatus, isSuccessDelete]);

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {defaultFormValues.documentNumber ? defaultFormValues.documentNumber : "не определен"}</p>
                <BackButton/>
            </div>
            <div className={formColumnsStyle}>
                <ControlledPaginateAsyncSelect control={control}
                                               rules={{required: {value: true, message: "Это обязательное поле"}}}
                                               name={"productionLine"} label={"Производственная линия"}
                                               request={doSearchAdministrativeAndFunctionalZoneGuidePage}
                                               mapFunc={(data: TablePage<AdministrativeAndFunctionalZoneGuideRow>) =>
                                                   data.rows.map(row =>
                                                       ({label: `${row.name}`, value: row.id})
                                                   )}
                                               isDisabled={(props.data && props.data.status !== ProductionOrderStatus.WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART) || props.readonly}
                                               filter={{
                                                   pageNumber: 0,
                                                   pageSize: 10,
                                                   search: null,
                                                   sortOrder: null,
                                                   sortedField: null,
                                                   purpose: AdministrativeAndFunctionalZonePurpose.PRODUCTION_LINE
                                               } as AdministrativeAndFunctionalZoneGuideFilter}/>
                {!(props.data && props.data.status === ProductionOrderStatus.COMPLETED) &&
                    <ControlledInputField control={control} rules={{
                        pattern: {
                            value: /^[1-9]\d*$/,
                            message: 'Введите положительное число',
                        }
                    }} name={"priority"} label={"Номер в очереди"} additionalStyles={"w-[423px]"}
                                          readonly={props.readonly}/>}
            </div>
            {props.data && <div>
                <SeparatingLine label={"Основная продукция"}/>
                <TableForMainProduct control={control}
                                     readonly={(props.data && props.data.status !== ProductionOrderStatus.WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART) || props.readonly}/>
                <SeparatingLine label={"Дополнительная комплектация"}/>
                <TableForAdditionalEquipments control={control} readonly={props.readonly} status={props.data?.status}/>
                <SeparatingLine label={"Выполнение производственного заказа"}/>
                <ProductionOrderExecutionTable creationTime={props.data.creationTime}
                                               addedInLineTime={props.data.addedInLineTime}
                                               productionStartTime={props.data.productionStartTime}
                                               completionTime={props.data.completionTime}/>
                <SeparatingLine label={"Выполнение по этапам производства"}/>
                <ExecutionByProductionStagesTable tableData={props.data.progress}/>
            </div>}
            <SeparatingLine label={"Дополнительная информация"}/>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    },
                    pattern: {
                        value: /^[1-9]\d*$/,
                        message: 'Введите положительное число',
                    }
                }} name={"workOrderNumber"} label={"№ Work"} additionalStyles={"w-[423px]"} readonly={props.readonly}/>
                <ControlledDatePicker control={control}
                                      rules={{required: {value: true, message: "Это обязательное поле"}}}
                                      name={"planningCompletionDate"} label={"Требуется завершить"}
                                      readonly={props.readonly || (props.data && props.data.status === ProductionOrderStatus.COMPLETED)}
                                      additionalStyles={"w-[423px]"}/>
                {props.data && <>
                    <BlockForDisplayingText text={props.data.workOrderInfo.type} label={"Тип"}/>
                    <BlockForDisplayingText text={props.data.workOrderInfo.drawingNumber} label={"Номер чертежа"}/>
                    <BlockForDisplayingText text={props.data.workOrderInfo.description} label={"Описание"}/>
                    <BlockForDisplayingText text={props.data.workOrderInfo.customer} label={"Заказчик"}/>
                    <BlockForDisplayingText text={props.data.workOrderInfo.managerComment}
                                            label={"Комментарий менеджера"}
                                            additionalNode={props.data.workOrderInfo.fileUrls.map((url, index) => <a
                                                className='w-[423px] block overflow-hidden text-blue-500 hover:underline'
                                                href={`https://work.umindustry.ru${url}`} target='_blank'
                                                rel='noopener noreferrer'>{`Вложение ${index + 1}`}</a>)}/>
                    <BlockForDisplayingText text={props.data.workOrderInfo.automation} label={"Автоматизация"}/>
                    <BlockForDisplayingText text={props.data.workOrderInfo.tensometry} label={"Тензометрия"}/>
                </>}
                <ControlledInputField control={control} rules={{}} name={"productionComment"}
                                      label={"Комментарий производства"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
            </div>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data && <Button buttonName={"Удалить"}
                                       onClickButton={() => doDeleteProductionOrder(props.data!.documentNumber)}/>}
                {!props.readonly && <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>}
                {props.readonly && props.data && props.data.status === ProductionOrderStatus.WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART &&
                    <Button buttonName={"Добавить в очередь"} onClickButton={() => doUpdateProductionOrderStatus({
                        number: props.data!.documentNumber,
                        body: ProductionOrderStatus.IN_LINE
                    })}/>}
                {props.readonly && props.data && <Button buttonName={"Редактировать"}
                                                         onClickButton={() => navigate(`/production-order/edit?number=${props.data!.documentNumber}`)}/>}
            </div>
        </form>
    )
}